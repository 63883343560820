import {
  DocumentMinusIcon,
  DocumentPlusIcon,
  HomeIcon,
  LanguageIcon,
  RectangleGroupIcon,
  ShoppingCartIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { ReactComponent as Beef } from 'assets/static/beef.svg';
import { ReactComponent as Chicken } from 'assets/static/chicken.svg';
import { ReactComponent as FishLabel } from 'assets/static/fish.svg';
import { ReactComponent as GlutenFree } from 'assets/static/gluten-free.svg';
import { ReactComponent as LactoseFree } from 'assets/static/lactoseFree.svg';
import { ReactComponent as Lamb } from 'assets/static/lamb.svg';
import { ReactComponent as Mushrooms } from 'assets/static/mushrooms.svg';
import { ReactComponent as Pork } from 'assets/static/pork.svg';
import { ReactComponent as Salmon } from 'assets/static/salmon.svg';
import { ReactComponent as Seafood } from 'assets/static/seafood.svg';
import { ReactComponent as Shrimp } from 'assets/static/shrimp.svg';
import { ReactComponent as Spicy } from 'assets/static/spicy.svg';
import { ReactComponent as Tuna } from 'assets/static/tuna.svg';
import { ReactComponent as Vegan } from 'assets/static/vegan.svg';
import { ReactComponent as Vegitarian } from 'assets/static/vegetarian.svg';
import {
  ITheme,
  Labels,
  Languages,
  OldLanguages,
  SelectedLanguage,
  ZoneInfo,
} from 'commonTypes';
import { NoSulfites } from 'components/Icons/NoSulfites';
import { Sulfites } from 'components/Icons/Sulfites';
import { RoundedOption } from 'components/QRCodeComponents/RoundedSelect';
import { ReactNode } from 'react';
import { ToastOptions } from 'react-toastify';

import {
  Celery,
  Crab,
  Eggs,
  Fish,
  Gluten,
  Milk,
  Mustard,
  NoCelery,
  NoCrab,
  NoEggs,
  NoFish,
  NoGluten,
  NoMilk,
  NoMustard,
  NoNuts,
  NoPea,
  NoSeeds,
  NoSesame,
  NoShellfish,
  NoSoy,
  Nuts,
  Pea,
  Seeds,
  Sesame,
  Shellfish,
  Soy,
} from './components/Icons';

export const defaultToastProps: ToastOptions = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const themes = [
  {
    name: 'Dark',
    id: 1,
    qr_code: 'типа ссылка',
  },
  {
    name: 'Light',
    id: 2,
    qr_code: 'типа ссылка',
  },
];

export const colorPresets: {
  dark: ITheme;
  light: ITheme;
} = {
  dark: {
    colors: [
      {
        id: 1,
        value: '#191918',
      },
      {
        id: 2,
        value: '#E0DEDA',
      },
      {
        id: 3,
        value: '#9E9B98',
      },
      {
        id: 4,
        value: '#f5f4f21a',
      },
      {
        id: 5,
        value: '#302F2D',
      },
      {
        id: 6,
        value: '#facc15',
      },
      {
        id: 7,
        value: '#42413E',
      },
      {
        id: 8,
        value: '#ffffff',
      },
    ],
    // Main elements start
    mainElementsMainBg: '#191918',
    mainElementsEstablishmentNameColor: '#ffffff',
    mainElementsOrderButtonBg: '#42413e',
    mainElementsOrderButtonColor: '#e0deda',
    mainElementsOrderButtonShadow: '#191918',
    mainElementsCategoryInMenuColor: '#e0deda',
    mainElementsMediaBlocks: '#191918',
    // Mein elements end

    // List of Menu & Categories start
    listMenuActiveMenuBg: '#f5f4f21a',
    listMenuActiveMenuText: '#e0deda',
    listMenuInactiveMenuTextColor: '#9e9b98',
    listMenuActiveCategory: '#e0deda',
    listMenuInactiveCategory: '#9e9b98',
    // List of Menu & Categories end

    // Choose Lang start
    chooseLangButtonBg: '#42413e',
    chooseLangButtonText: '#e0deda',
    choosenLangColor: '#e0deda',
    choosenLangBg: '#f5f4f21a',
    chooseLangMoreLangsColor: '#e0deda',
    chooseLangSearchLangBg: '#f5f4f21a',
    chooseLangSearchLangColor: '#e0deda',
    // Choose Lang end

    // Closed item Card start
    closedItemCardBg: '#302f2d',
    closedItemCardText: '#e0deda',
    closedItemCardAddButtonBg: '#42413e',
    closedItemCardAddButtonText: '#e0deda',
    // Closed item Card end

    // Opem item Card start
    openItemCardBg: '#302f2d',
    openItemCardText: '#e0deda',
    openItemCardAddButtonBg: '#f5f4f21a',
    openItemCardAddButtonText: '#e0deda',
    openItemCardCounterBg: '#42413e',
    openItemCardCounterText: '#e0deda',
    openItemCardSuggestionsText: '#9e9b98',
    openItemCardSuggestionsCardBg: '#42413e',
    openItemCardSuggestionsCardText: '#e0deda',
    openItemCardSuggestionsAddButtonBg: '#302f2d',
    openItemCardSuggestionsAddButtonText: '#e0deda',
    // Opem item Card end

    // Order page start
    orderPageTextColor: '#e0deda',
    orderPageDelimiterColor: '#9e9b98',
    orderPageAddButtonBg: '#42413e',
    orderPageAddButtonText: '#e0deda',
    orderPageHintBg: '#302F2D',
    orderPageHintText: '#E0DEDA',
    orderPageReviewCallToActionBg: '#f5f4f21a',
    orderPageReviewCallToActionArrow: '#e0deda',
    // Order page end

    // Open Review block start
    openReviewBlockBg: '#42413e',
    openReviewBlockUnratedStar: '#9E9B98',
    openReviewBlockRatedStar: '#facc15',
    openReviewBlockHeadingColor: '#e0deda',
    openReviewBlockDescriptionText: '#9e9b98',
    openReviewBlockLinkButtonBg: '#302f2d',
    openReviewBlockLinkButtonText: '#e0deda',
    // Open Review block end
    fontSize: 36,
  },
  light: {
    colors: [
      {
        id: 1,
        value: '#ffffff',
      },
      {
        id: 2,
        value: '#21201F',
      },
      {
        id: 3,
        value: '#9e9b98',
      },
      {
        id: 4,
        value: '#000000',
      },
      {
        id: 5,
        value: '#f5f4f2',
      },
      {
        id: 6,
        value: '#facc15',
      },
      {
        id: 7,
        value: '#dcf4ca',
      },
      {
        id: 8,
        value: '#239808',
      },
    ],
    mainElementsMainBg: '#ffffff',
    mainElementsEstablishmentNameColor: '#ffffff',
    mainElementsOrderButtonBg: '#ffffff',
    mainElementsOrderButtonColor: '#21201f',
    mainElementsOrderButtonShadow: '#9e9b98',
    mainElementsCategoryInMenuColor: '#21201f',
    mainElementsMediaBlocks: '#ffffff',
    listMenuActiveMenuBg: '#000000',
    listMenuActiveMenuText: '#ffffff',
    listMenuInactiveMenuTextColor: '#9e9b98',
    listMenuActiveCategory: '#21201f',
    listMenuInactiveCategory: '#9e9b98',
    chooseLangButtonBg: '#ffffff',
    chooseLangButtonText: '#21201f',
    choosenLangColor: '#ffffff',
    choosenLangBg: '#000000',
    chooseLangMoreLangsColor: '#21201f',
    chooseLangSearchLangBg: '#ffffff',
    chooseLangSearchLangColor: '#21201f',
    closedItemCardBg: '#f5f4f2',
    closedItemCardText: '#21201f',
    closedItemCardAddButtonBg: '#ffffff',
    closedItemCardAddButtonText: '#000000',
    openItemCardBg: '#f5f4f2',
    openItemCardText: '#21201f',
    openItemCardAddButtonBg: '#000000',
    openItemCardAddButtonText: '#ffffff',
    openItemCardCounterBg: '#ffffff',
    openItemCardCounterText: '#21201f',
    openItemCardSuggestionsText: '#9e9b98',
    openItemCardSuggestionsCardBg: '#ffffff',
    openItemCardSuggestionsCardText: '#21201f',
    openItemCardSuggestionsAddButtonBg: '#f5f4f2',
    openItemCardSuggestionsAddButtonText: '#000000',
    orderPageTextColor: '#21201f',
    orderPageDelimiterColor: '#9e9b98',
    orderPageAddButtonBg: '#ffffff',
    orderPageAddButtonText: '#000000',
    orderPageHintBg: '#dcf4ca',
    orderPageHintText: '#239808',
    orderPageReviewCallToActionBg: '#000000',
    orderPageReviewCallToActionArrow: '#ffffff',
    openReviewBlockBg: '#ffffff',
    openReviewBlockUnratedStar: '#9E9B98',
    openReviewBlockRatedStar: '#facc15',
    openReviewBlockHeadingColor: '#21201f',
    openReviewBlockDescriptionText: '#9E9B98',
    openReviewBlockLinkButtonBg: '#f5f4f2',
    openReviewBlockLinkButtonText: '#21201f',
    fontSize: 36,
  },
};

export const colorsSections: Record<number, string[]> = {
  1: [
    'mainElementsMainBg',
    'mainElementsEstablishmentNameColor',
    'mainElementsMediaBlocks',
    'mainElementsOrderButtonBg',
    'mainElementsOrderButtonColor',
    'mainElementsOrderButtonShadow',
    'mainElementsCategoryInMenuColor',
  ],
  2: [
    'listMenuActiveMenuBg',
    'listMenuActiveMenuText',
    'listMenuInactiveMenuTextColor',
    'listMenuActiveCategory',
    'listMenuInactiveCategory',
  ],
  3: [
    'chooseLangButtonBg',
    'chooseLangButtonText',
    'choosenLangColor',
    'choosenLangBg',
    'chooseLangMoreLangsColor',
    'chooseLangSearchLangBg',
    'chooseLangSearchLangColor',
  ],
  4: [
    'closedItemCardBg',
    'closedItemCardText',
    'closedItemCardAddButtonBg',
    'closedItemCardAddButtonText',
  ],
  5: [
    'openItemCardBg',
    'openItemCardText',
    'openItemCardAddButtonBg',
    'openItemCardAddButtonText',
    'openItemCardCounterBg',
    'openItemCardCounterText',
    'openItemCardSuggestionsText',
    'openItemCardSuggestionsCardBg',
    'openItemCardSuggestionsCardText',
    'openItemCardSuggestionsAddButtonBg',
    'openItemCardSuggestionsAddButtonText',
  ],
  6: [
    'orderPageTextColor',
    'orderPageDelimiterColor',
    'orderPageAddButtonBg',
    'orderPageAddButtonText',
    'orderPageHintBg',
    'orderPageHintText',
    'orderPageReviewCallToActionBg',
    'orderPageReviewCallToActionArrow',
  ],
  7: [
    'openReviewBlockBg',
    'openReviewBlockUnratedStar',
    'openReviewBlockRatedStar',
    'openReviewBlockHeadingColor',
    'openReviewBlockDescriptionText',
    'openReviewBlockLinkButtonBg',
    'openReviewBlockLinkButtonText',
  ],
};

export const colorTypes = [
  {
    title: 'mainElementsColors',
    icon: HomeIcon,
    id: 1,
    action: ['closeCard', 'backToMainPage', 'closeLangMenu'],
  },
  {
    title: 'listOfMenuCategories',
    icon: RectangleGroupIcon,
    id: 2,
    action: ['closeCard', 'backToMainPage', 'closeLangMenu'],
  },
  {
    title: 'chooseLang',
    icon: LanguageIcon,
    id: 3,
    action: ['openLangMenu'],
  },
  {
    title: 'closedItemCard',
    icon: DocumentMinusIcon,
    id: 4,
    action: ['closeCard', 'backToMainPage', 'closeLangMenu'],
  },
  {
    title: 'openItemCard',
    icon: DocumentPlusIcon,
    id: 5,
    action: ['backToMainPage', 'openCard', 'closeLangMenu'],
  },
  {
    title: 'orderPage',
    icon: ShoppingCartIcon,
    id: 6,
    action: ['addToOrder', 'closeLangMenu'],
  },
  {
    title: 'openReview',
    icon: StarIcon,
    id: 7,
    action: ['addToOrder', 'openReview', 'closeLangMenu'],
  },
];

export const phoneCodesObj = {
  '+43': { code: '+43', name: 'AT' },
  '+32': { code: '+32', name: 'BE' },
  '+359': { code: '+359', name: 'BG' },
  '+385': { code: '+385', name: 'HR' },
  '+357': { code: '+357', name: 'CY' },
  '+420': { code: '+420', name: 'CZ' },
  '+45': { code: '+45', name: 'DK' },
  '+372': { code: '+372', name: 'EE' },
  '+358': { code: '+358', name: 'FI' },
  '+33': { code: '+33', name: 'FR' },
  '+49': { code: '+49', name: 'DE' },
  '+30': { code: '+30', name: 'GR' },
  '+36': { code: '+36', name: 'HU' },
  '+353': { code: '+353', name: 'IE' },
  '+39': { code: '+39', name: 'IT' },
  '+972': { code: '+972', name: 'IL' },
  '+371': { code: '+371', name: 'LV' },
  '+370': { code: '+370', name: 'LT' },
  '+352': { code: '+352', name: 'LU' },
  '+356': { code: '+356', name: 'MT' },
  '+31': { code: '+31', name: 'NL' },
  '+47': { code: '+47', name: 'NO' },
  '+48': { code: '+48', name: 'PL' },
  '+351': { code: '+351', name: 'PT' },
  '+40': { code: '+40', name: 'RO' },
  '+381': { code: '+381', name: 'RS' },
  '+421': { code: '+421', name: 'SK' },
  '+386': { code: '+386', name: 'SI' },
  '+34': { code: '+34', name: 'ES' },
  '+46': { code: '+46', name: 'SE' },
  '+423': { code: '+423', name: 'LI' },
  '+44': { code: '+44', name: 'UK' },
  '+90': { code: '+90', name: 'TR' },
  '+380': { code: '+380', name: 'UA' },
  '+7': { code: '+7', name: 'RU' },
  '+41': { code: '+41', name: 'CH' },
  '+373': { code: '+373', name: 'MD' },
  '+995': { code: '+995', name: 'GE' },
  '+375': { code: '+375', name: 'BY' },
  '+374': { code: '+374', name: 'AM' },
  '+376': { code: '+376', name: 'AD' },
  '+66': { code: '+66', name: 'TH' },
};

export const phoneCodes = [
  { code: '+43', name: 'AT' },
  { code: '+32', name: 'BE' },
  { code: '+359', name: 'BG' },
  { code: '+385', name: 'HR' },
  { code: '+357', name: 'CY' },
  { code: '+66', name: 'TH' },
  { code: '+420', name: 'CZ' },
  { code: '+45', name: 'DK' },
  { code: '+372', name: 'EE' },
  { code: '+358', name: 'FI' },
  { code: '+33', name: 'FR' },
  { code: '+49', name: 'DE' },
  { code: '+30', name: 'GR' },
  { code: '+36', name: 'HU' },
  { code: '+353', name: 'IE' },
  { code: '+39', name: 'IT' },
  { code: '+972', name: 'IL' },
  { code: '+371', name: 'LV' },
  { code: '+370', name: 'LT' },
  { code: '+352', name: 'LU' },
  { code: '+356', name: 'MT' },
  { code: '+31', name: 'NL' },
  { code: '+47', name: 'NO' },
  { code: '+48', name: 'PL' },
  { code: '+351', name: 'PT' },
  { code: '+40', name: 'RO' },
  { code: '+381', name: 'RS' },
  { code: '+421', name: 'SK' },
  { code: '+386', name: 'SI' },
  { code: '+34', name: 'ES' },
  { code: '+46', name: 'SE' },
  { code: '+423', name: 'LI' },
  { code: '+44', name: 'UK' },
  { code: '+90', name: 'TR' },
  { code: '+380', name: 'UA' },
  { code: '+7', name: 'RU' },
  { code: '+41', name: 'CH' },
  { code: '+373', name: 'MD' },
  { code: '+995', name: 'GE' },
  { code: '+375', name: 'BY' },
  { code: '+374', name: 'AM' },
  { code: '+376', name: 'AD' },
];

export const currencyData = [
  { code: 'USD', name: 'United States Dollar' },
  { code: 'ILS', name: 'Israeli Shekel' },
  { code: 'HUF', name: 'Hungarian Forint' },
  { code: 'RUB', name: 'Russian Ruble' },
  { code: 'EUR', name: 'Euro' },
  { code: 'THB', name: 'Thai baht' },
  { code: 'AED', name: 'Arab Emirates Dirham' },
  { code: 'AMD', name: 'Armenian Dram' },
  { code: 'ARS', name: 'Argentinian Nuevo peso' },
  { code: 'AZN', name: 'Azerbaijani manat' },
  { code: 'BGN', name: 'Bulgarian lev' },
  { code: 'CHF', name: 'Swiss frank' },
  { code: 'CZK', name: 'Czech koruna' },
  { code: 'DKK', name: 'Danish krone' },
  { code: 'GBP', name: 'Great Britain Pound' },
  { code: 'KZT', name: 'Kazakhstani tenge' },
  { code: 'MVR', name: 'Maldivian rufiyaa' },
  { code: 'NOK', name: 'Norwegian krone' },
  { code: 'PLN', name: 'Polish złoty' },
  { code: 'RON', name: 'Romanian leu' },
  { code: 'RSD', name: 'Serbian dinar' },
  { code: 'SEK', name: 'Swedish krona' },
  { code: 'TRY', name: 'Turkish lira' },
  { code: 'UAH', name: 'Ukrainian hryvnia' },
];

export const selectLanguages: SelectedLanguage[] = [
  { value: 'af', label: 'Afrikaans', oldCode: 'af-AF', dlCode: 'af_AF' },
  { value: 'sq', label: 'Albanian', oldCode: 'sq-SQ', dlCode: 'sq_SQ' },
  { value: 'am', label: 'Amharic', oldCode: 'am-AM', dlCode: 'am_AM' },
  {
    value: 'ar',
    label: 'Arabic',
    oldCode: 'ar-AR',
    dlCode: 'ar_AR',
    dir: 'rtl',
  },
  { value: 'hy', label: 'Armenian', oldCode: 'hy-HY', dlCode: 'hy_HY' },
  { value: 'az', label: 'Azerbaijani', oldCode: 'az-AZ', dlCode: 'az_AZ' },
  { value: 'bn', label: 'Bengali', oldCode: 'bn-BN', dlCode: 'bn_BN' },
  { value: 'bs', label: 'Bosnian', oldCode: 'bs-BS', dlCode: 'bs_BS' },
  { value: 'bg', label: 'Bulgarian', oldCode: 'bg-BG', dlCode: 'bg_BG' },
  { value: 'ca', label: 'Catalan', oldCode: 'ca-CA', dlCode: 'ca_CA' },
  { value: 'de', label: 'German', oldCode: 'de-DE', dlCode: 'de_DE' },
  { value: 'zh', label: 'Chinese', oldCode: 'zh-ZH', dlCode: 'zh_ZH' },
  { value: 'hr', label: 'Croatian', oldCode: 'hr-HR', dlCode: 'hr_HR' },
  { value: 'cs', label: 'Czech', oldCode: 'cs-CS', dlCode: 'cs_CS' },
  { value: 'da', label: 'Danish', oldCode: 'da-DA', dlCode: 'da_DA' },
  { value: 'nl', label: 'Dutch', oldCode: 'nl-NL', dlCode: 'nl_NL' },
  { value: 'en', label: 'English', oldCode: 'en-EN', dlCode: 'en_EN' },
  { value: 'et', label: 'Estonian', oldCode: 'et-ET', dlCode: 'et_ET' },
  {
    value: 'fa',
    label: 'Farsi',
    oldCode: 'fa-FA',
    dlCode: 'fa_FA',
    dir: 'rtl',
  },
  { value: 'tl', label: 'Filipino', oldCode: 'tl-TL', dlCode: 'tl_TL' },
  { value: 'fi', label: 'Finnish', oldCode: 'fi-FI', dlCode: 'fi_FI' },
  { value: 'fr', label: 'French', oldCode: 'fr-FR', dlCode: 'fr_FR' },
  { value: 'ka', label: 'Georgian', oldCode: 'ka-KA', dlCode: 'ka_KA' },
  { value: 'el', label: 'Greek', oldCode: 'el-EL', dlCode: 'el_EL' },
  { value: 'gu', label: 'Gujarati', oldCode: 'gu-GU', dlCode: 'gu_GU' },
  { value: 'ht', label: 'Haitian', oldCode: 'ht-HT', dlCode: 'ht_HT' },
  { value: 'ha', label: 'Hausa', oldCode: 'ha-HA', dlCode: 'ha_HA' },
  {
    value: 'he',
    label: 'Hebrew',
    oldCode: 'he-HE',
    dlCode: 'he_HE',
    dir: 'rtl',
  },
  { value: 'hi', label: 'Hindi', oldCode: 'hi-HI', dlCode: 'hi_HI' },
  { value: 'hu', label: 'Hungarian', oldCode: 'hu-HU', dlCode: 'hu_HU' },
  { value: 'is', label: 'Icelandic', oldCode: 'is-IS', dlCode: 'is_IS' },
  { value: 'id', label: 'Indonesian', oldCode: 'id-ID', dlCode: 'id_ID' },
  { value: 'ga', label: 'Irish', oldCode: 'ga-GA', dlCode: 'ga_GA' },
  { value: 'it', label: 'Italian', oldCode: 'it-IT', dlCode: 'it_IT' },
  { value: 'ja', label: 'Japanese', oldCode: 'ja-JA', dlCode: 'ja_JA' },
  { value: 'kn', label: 'Kannada', oldCode: 'kn-KN', dlCode: 'kn_KN' },
  { value: 'kk', label: 'Kazakh', oldCode: 'kk-KK', dlCode: 'kk_KK' },
  { value: 'ko', label: 'Korean', oldCode: 'ko-KO', dlCode: 'ko_KO' },
  { value: 'lv', label: 'Latvian', oldCode: 'lv-LV', dlCode: 'lv_LV' },
  { value: 'lt', label: 'Lithuanian', oldCode: 'lt-LT', dlCode: 'lt_LT' },
  { value: 'mk', label: 'Macedonian', oldCode: 'mk-MK', dlCode: 'mk_MK' },
  { value: 'ms', label: 'Malay', oldCode: 'ms-MS', dlCode: 'ms_MS' },
  { value: 'ml', label: 'Malayalam', oldCode: 'ml-ML', dlCode: 'ml_ML' },
  { value: 'mt', label: 'Maltese', oldCode: 'mt-MT', dlCode: 'mt_MT' },
  { value: 'mr', label: 'Marathi', oldCode: 'mr-MR', dlCode: 'mr_MR' },
  { value: 'mn', label: 'Mongolian', oldCode: 'mn-MN', dlCode: 'mn_MN' },
  { value: 'no', label: 'Norwegian', oldCode: 'no-NO', dlCode: 'no_NO' },
  {
    value: 'ps',
    label: 'Pashto',
    oldCode: 'ps-PS',
    dlCode: 'ps_PS',
    dir: 'rtl',
  },
  { value: 'pl', label: 'Polish', oldCode: 'pl-PL', dlCode: 'pl_PL' },
  { value: 'pt', label: 'Portuguese', oldCode: 'pt-PT', dlCode: 'pt_PT' },
  { value: 'pa', label: 'Punjabi', oldCode: 'pa-PA', dlCode: 'pa_PA' },
  { value: 'ro', label: 'Romanian', oldCode: 'ro-RO', dlCode: 'ro_RO' },
  { value: 'ru', label: 'Russian', oldCode: 'ru-RU', dlCode: 'ru_RU' },
  { value: 'sr', label: 'Serbian', oldCode: 'sr-SR', dlCode: 'sr_SR' },
  { value: 'si', label: 'Sinhala', oldCode: 'si-SI', dlCode: 'si_SI' },
  { value: 'sk', label: 'Slovak', oldCode: 'sk-SK', dlCode: 'sk_SK' },
  { value: 'sl', label: 'Slovenian', oldCode: 'sl-SL', dlCode: 'sl_SL' },
  { value: 'so', label: 'Somali', oldCode: 'so-SO', dlCode: 'so_SO' },
  { value: 'es', label: 'Spanish', oldCode: 'es-ES', dlCode: 'es_ES' },
  { value: 'sw', label: 'Swahili', oldCode: 'sw-SW', dlCode: 'sw_SW' },
  { value: 'sv', label: 'Swedish', oldCode: 'sv-SV', dlCode: 'sv_SV' },
  { value: 'ta', label: 'Tamil', oldCode: 'ta-TA', dlCode: 'ta_TA' },
  { value: 'te', label: 'Telugu', oldCode: 'te-TE', dlCode: 'te_TE' },
  { value: 'th', label: 'Thai', oldCode: 'th-TH', dlCode: 'th_TH' },
  { value: 'tr', label: 'Turkish', oldCode: 'tr-TR', dlCode: 'tr_TR' },
  { value: 'uk', label: 'Ukrainian', oldCode: 'uk-UK', dlCode: 'uk_UK' },
  {
    value: 'ur',
    label: 'Urdu',
    oldCode: 'ur-UR',
    dlCode: 'ur_UR',
    dir: 'rtl',
  },
  { value: 'uz', label: 'Uzbek', oldCode: 'uz-UZ', dlCode: 'uz_UZ' },
  { value: 'vi', label: 'Vietnamese', oldCode: 'vi-VI', dlCode: 'vi_VI' },
  { value: 'cy', label: 'Welsh', oldCode: 'cy-CY', dlCode: 'cy_CY' },
];

export const selectLanguagesGPT: SelectedLanguage[] = [
  { value: 'de', label: 'German', oldCode: 'de-DE', dlCode: 'de_DE' },
  { value: 'en', label: 'English', oldCode: 'en-EN', dlCode: 'en_EN' },
  { value: 'fr', label: 'French', oldCode: 'fr-FR', dlCode: 'fr_FR' },
  { value: 'hu', label: 'Hungarian', oldCode: 'hu-HU', dlCode: 'hu_HU' },
  { value: 'it', label: 'Italian', oldCode: 'it-IT', dlCode: 'it_IT' },
];

export const translateGpt = {
  promptExplonetion: {
    de: 'Eine Aufforderung für ChatGPT ist wie eine kleine Notiz, die Sie der KI geben, um eine Antwort zu erhalten. Sie könnten zum Beispiel sagen: "Schreiben Sie eine Beschreibung von Gulasch" oder "Beschreiben Sie die Nährstoffe von griechischem Salat". Es ist wie ein Hinweis oder eine Anweisung, damit die KI die richtige Antwort geben kann.',
    en: 'A prompt for ChatGPT is like a little note you give to the AI to get an answer. For example, you might say, "Write a description of goulash" or "Describe the nutrients of Greek salad". It\'s like a clue or direction so that the AI can give the right answer.',
    fr: "Une invite pour ChatGPT est comme une petite note que vous donnez à l'IA pour obtenir une réponse. Par exemple, vous pouvez dire : \"Décrivez le goulasch\" ou \"Décrivez les éléments nutritifs de la salade grecque\". Il s'agit d'un indice ou d'une indication permettant à l'IA de donner la bonne réponse.",
    hu: 'A ChatGPT kérése olyan, mint egy kis üzenet, amelyet a mesterséges intelligenciának adsz, hogy választ kapjon. Például mondhatod, hogy "Írj leírást a gulyásról" vagy "Írd le a görög saláta tápanyagtartalmát". Ez olyan, mint egy nyom vagy útmutatás, hogy a mesterséges intelligencia meg tudja adni a helyes választ.',
    it: 'Un prompt per ChatGPT è come una piccola nota che si dà all\'IA per ottenere una risposta. Ad esempio, si potrebbe dire: "Scrivi una descrizione del gulasch" o "Descrivi i nutrienti dell\'insalata greca". È come un indizio o una direzione per consentire all\'IA di dare la risposta giusta.',
  },
  langRecommend: {
    de: 'Wir empfehlen, die Prompts auf Englisch zu schreiben, um beste Ergebnisse zu erzielen',
    en: 'We recommend that you write the Prompts in English for best results',
    fr: 'Nous vous recommandons de rédiger les questions en anglais pour obtenir de meilleurs résultats.',
    hu: 'A legjobb eredmény érdekében javasoljuk, hogy a Prompts-t angolul írja meg.',
    it: 'Per ottenere risultati ottimali, si consiglia di scrivere i Prompt in inglese.',
  },
  title: {
    de: 'Beschreibung generieren',
    en: 'Generate description',
    fr: 'Générer une description',
    hu: 'Leírás generálása',
    it: 'Generare una descrizione',
  },
  listPrompts: {
    de: 'Liste der Prompts',
    en: 'List of Prompts',
    fr: 'Liste des messages-guides',
    hu: 'Felszólítások listája',
    it: 'Elenco dei suggerimenti',
  },
  noPrompts: {
    de: 'Es gibt keine zusätzliche Aufforderung',
    en: 'There are no additional prompt',
    fr: "Il n'y a pas de demande supplémentaire",
    hu: 'Nincsenek további kérések',
    it: 'Non ci sono richieste aggiuntive',
  },
  addCustomPrompt: {
    de: 'Benutzerdefinierte Eingabeaufforderung hinzufügen',
    en: 'Add custom prompt',
    fr: 'Ajouter une invite personnalisée',
    hu: 'Egyéni prompt hozzáadása',
    it: 'Aggiungere un prompt personalizzato',
  },
  cancel: {
    de: 'Abbrechen',
    en: 'Cancel',
    fr: 'Annuler',
    hu: 'Törölje',
    it: 'Annullamento',
  },
  add: {
    de: 'Hinzufügen',
    en: 'Add',
    fr: 'Ajouter',
    hu: 'Hozzáadása',
    it: 'Aggiungi',
  },
  finalPrompt: {
    de: 'Schreiben Sie eine Beschreibung für',
    en: 'Write a description for',
    fr: 'Rédiger une description pour',
    hu: 'Írjon leírást a',
    it: 'Scrivere una descrizione per',
  },
  previous: {
    de: 'Vorherige',
    en: 'Previous',
    fr: 'Précédent',
    hu: 'Előző',
    it: 'Precedente',
  },
  generate: {
    de: 'Erzeugen Sie',
    en: 'Generate',
    fr: 'Générer',
    hu: 'Generálja',
    it: 'Generare',
  },
  confirm: {
    de: 'Bestätigen Sie',
    en: 'Confirm',
    fr: 'Confirmer',
    hu: 'Megerősítés',
    it: 'Confermare',
  },
  finalStepText: {
    de: 'Sie können die Abfrage neu generieren, um eine andere Option zu erhalten, oder die Ergebnisse bestätigen und mit der Erstellung des Artikels fortfahren',
    en: 'You can regenerate the query to get another option, or confirm the results and continue creating the item',
    fr: "Vous pouvez régénérer la requête pour obtenir une autre option, ou confirmer les résultats et poursuivre la création de l'article.",
    hu: 'Újra létrehozhatja a lekérdezést, hogy egy másik lehetőséget kapjon, vagy megerősítheti az eredményeket, és folytathatja az elem létrehozását.',
    it: "È possibile rigenerare la query per ottenere un'altra opzione, oppure confermare i risultati e continuare a creare l'elemento.",
  },
  regenerate: {
    de: 'Regenerieren Sie',
    en: 'Regenerate',
    fr: 'Régénérer',
    hu: 'Regenerálja',
    it: 'Rigenerare',
  },
  textResult: {
    de: 'Text Ergebnis',
    en: 'Text result',
    fr: 'Résultat du texte',
    hu: 'Szöveges eredmény',
    it: 'Risultato del testo',
  },
  inShort1: {
    de: 'Die Beschreibung sollte maximal ',
    en: 'Make the description max ',
    fr: 'La description ne doit pas dépasser ',
    hu: 'A leírás maximum ',
    it: 'La descrizione deve essere lunga al massimo ',
  },
  inShort2: {
    de: ' Zeichen lang sein.',
    en: ' characters long.',
    fr: ' caractères.',
    hu: ' karakter hosszú lehet.',
    it: ' caratteri.',
  },
  maxLength: {
    de: 'Maximale Länge der Beschreibung',
    en: 'Maximum description length',
    fr: 'Longueur maximale de la description',
    hu: 'Maximális leírás hossza',
    it: 'Lunghezza massima della descrizione',
  },
  prompts: {
    1: {
      de: 'Beschreiben Sie die Nährstoffe und den Kaloriengehalt des Gerichts',
      en: 'Describe the nutrients and calories of the dish',
      fr: 'Décrire les nutriments et les calories contenus dans le plat',
      hu: 'Írja le az étel tápanyag- és kalóriatartalmát.',
      it: 'Descrivere le sostanze nutritive e le calorie contenute nel piatto',
    },
    2: {
      de: 'Was ist die kulturelle oder regionale Inspiration für das Gericht?',
      en: 'What is the cultural or regional inspiration behind the dish?',
      fr: "Quelle est l'inspiration culturelle ou régionale du plat?",
      hu: 'Milyen kulturális vagy regionális inspiráció áll az étel mögött?',
      it: "Qual è l'ispirazione culturale o regionale alla base del piatto?",
    },
    3: {
      de: 'Was sind die wichtigsten Zutaten und Geschmacksrichtungen des Gerichts?',
      en: 'What are the main ingredients and flavors of the dish?',
      fr: 'Quels sont les principaux ingrédients et saveurs du plat?',
      hu: 'Melyek az étel fő összetevői és ízei?',
      it: 'Quali sono gli ingredienti e i sapori principali del piatto?',
    },
    4: {
      de: 'Welche kontrastierenden Geschmacksrichtungen oder Texturen weist das Gericht auf?',
      en: 'What are the contrasting flavors or textures in the dish?',
      fr: 'Quels sont les contrastes de saveurs ou de textures dans le plat ?',
      hu: 'Milyen kontrasztos ízek vagy textúrák vannak az ételben?',
      it: 'Quali sono i sapori o le consistenze contrastanti del piatto?',
    },
  },
};

export const basicPlan = [
  { name: 'createQR', check: true },
  { name: 'getAccess', check: true },
  { name: 'support', check: true },
  { name: 'personalManager', check: false },
  { name: 'cardsWithQR', check: false },
  { name: 'printedCards', check: false },
  { name: 'userExperience', check: false },
];

export const standartPlan = [
  { name: 'createQR', check: true },
  { name: 'getAccess', check: true },
  { name: 'support', check: true },
  { name: 'personalManager', check: true },
  { name: 'cardsWithQR', check: true },
  { name: 'printedCards', check: false },
  { name: 'userExperience', check: false },
];

export const proPlan = [
  { name: 'createQR', check: true },
  { name: 'getAccess', check: true },
  { name: 'support', check: true },
  { name: 'personalManager', check: true },
  { name: 'cardsWithQR', check: true },
  { name: 'printedCards', check: true },
  { name: 'userExperience', check: true },
];

export const oldLanguages: Record<OldLanguages, Languages> = {
  'hu-HU': 'hu',
  'en-EN': 'en',
  'de-DE': 'de',
  'ru-RU': 'ru',
  'sq-SQ': 'sq',
  'bg-BG': 'bg',
  'zh-ZH': 'zh',
  'hr-HR': 'hr',
  'cs-CS': 'cs',
  'nl-NL': 'nl',
  'fi-FI': 'fi',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'id-ID': 'id',
  'lv-LV': 'lv',
  'lt-LT': 'lt',
  'no-NO': 'no',
  'mt-MT': 'mt',
  'pl-PL': 'pl',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'sk-SK': 'sk',
  'sl-SL': 'sl',
  'es-ES': 'es',
  'sv-SV': 'sv',
  'tr-TR': 'tr',
  'th-TH': 'th',
  'da-DA': 'da',
  'et-ET': 'et',
  'el-EL': 'el',
  'hi-HI': 'hi',
  'ja-JA': 'ja',
  'ko-KO': 'ko',
  'mk-MK': 'mk',
  'tl-TL': 'tl',
  'vi-VI': 'vi',
  'af-AF': 'af',
  'am-AM': 'am',
  'ar-AR': 'ar',
  'hy-HY': 'hy',
  'az-AZ': 'az',
  'bn-BN': 'bn',
  'bs-BS': 'bs',
  'ca-CA': 'ca',
  'fa-FA': 'fa',
  'ka-KA': 'ka',
  'gu-GU': 'gu',
  'ht-HT': 'ht',
  'ha-HA': 'ha',
  'he-HE': 'he',
  'is-IS': 'is',
  'ga-GA': 'ga',
  'kn-KN': 'kn',
  'kk-KK': 'kk',
  'ms-MS': 'ms',
  'ml-ML': 'ml',
  'mr-MR': 'mr',
  'mn-MN': 'mn',
  'ps-PS': 'ps',
  'pa-PA': 'pa',
  'sr-SR': 'sr',
  'si-SI': 'si',
  'so-SO': 'so',
  'sw-SW': 'sw',
  'ta-TA': 'ta',
  'te-TE': 'te',
  'uk-UK': 'uk',
  'ur-UR': 'ur',
  'uz-UZ': 'uz',
  'cy-CY': 'cy',
};

export const allergens = {
  crustaceans: 0,
  celery: 0,
  eggs: 0,
  fish: 0,
  gluten: 0,
  milk: 0,
  mustard: 0,
  nuts: 0,
  pea: 0,
  sesame: 0,
  shellfish: 0,
  soy: 0,
  sulfites: 0,
  seeds: 0,
};

export const zoneInfo: ZoneInfo[] = [
  //{ label: 'Asia/Tel_Aviv', lang: 'he' },
  //{ label: 'Asia/Jerusalem', lang: 'he' },
  { label: 'Europe/Amsterdam', lang: 'nl', country: 'Netherlands' },
  { label: 'Europe/Andorra', lang: 'ca', country: 'Andorra' },
  { label: 'Europe/Athens', lang: 'el', country: 'Greece' },
  { label: 'Europe/Belfast', lang: 'en', country: 'Northern Ireland' },
  { label: 'Europe/Belgrade', lang: 'sr', country: 'Serbia' },
  { label: 'Europe/Berlin', lang: 'de', country: 'Germany' },
  { label: 'Europe/Bratislava', lang: 'sk', country: 'Slovakia' },
  { label: 'Europe/Brussels', lang: 'nl', country: 'Belgium' },
  { label: 'Europe/Bucharest', lang: 'ro', country: 'Romania' },
  { label: 'Europe/Budapest', lang: 'hu', country: 'Hungary' },
  //{ label: 'Europe/Busingen', lang: 'de', country: '' },
  { label: 'Europe/Chisinau', lang: 'ro', country: 'Moldova' },
  { label: 'Europe/Copenhagen', lang: 'da', country: 'Denmark' },
  { label: 'Europe/Dublin', lang: 'en', country: 'Ireland' },
  //{ label: 'Europe/Gibraltar', lang: 'en', country: '' },
  //{ label: 'Europe/Guernsey', lang: 'en', country: '' },
  { label: 'Europe/Helsinki', lang: 'fi', country: 'Finland' },
  //{ label: 'Europe/Isle_of_Man', lang: 'en', country: '' },
  { label: 'Europe/Istanbul', lang: 'tr', country: 'Turkey' },
  //{ label: 'Europe/Jersey', lang: 'en', country: '' },
  //{ label: 'Europe/Kaliningrad', lang: 'ru', country: '' },
  { label: 'Europe/Kiev', lang: 'uk', country: 'Ukraine' },
  { label: 'Europe/Lisbon', lang: 'pt', country: 'Portugal' },
  { label: 'Europe/Ljubljana', lang: 'sl', country: 'Slovenia' },
  { label: 'Europe/London', lang: 'en', country: 'England' },
  { label: 'Europe/Luxembourg', lang: 'fr', country: 'Luxembourg' },
  { label: 'Europe/Madrid', lang: 'es', country: 'Spain' },
  { label: 'Europe/Malta', lang: 'mt', country: 'Malta' },
  //{ label: 'Europe/Mariehamn', lang: 'fi', country: '' },
  { label: 'Europe/Minsk', lang: 'ru', country: 'Belarus' },
  { label: 'Europe/Monaco', lang: 'fr', country: 'Monaco' },
  { label: 'Europe/Moscow', lang: 'ru', country: 'Russia' },
  { label: 'Europe/Nicosia', lang: 'el', country: 'Republic of Cyprus' },
  { label: 'Europe/Oslo', lang: 'no', country: 'Norway' },
  { label: 'Europe/Paris', lang: 'fr', country: 'France' },
  { label: 'Europe/Podgorica', lang: 'sr', country: 'Montenegro' },
  { label: 'Europe/Prague', lang: 'cs', country: 'Czech Republic' },
  { label: 'Europe/Riga', lang: 'lv', country: 'Latvia' },
  { label: 'Europe/Rome', lang: 'it', country: 'Italy' },
  //{ label: 'Europe/Samara', lang: 'ru' },
  { label: 'Europe/San_Marino', lang: 'it', country: 'San Marino' },
  { label: 'Europe/Sarajevo', lang: 'bs', country: 'Bosnia and Herzegovina' },
  //{ label: 'Europe/Simferopol', lang: 'ru', country: '' },
  { label: 'Europe/Skopje', lang: 'mk', country: 'North Macedonia' },
  { label: 'Europe/Sofia', lang: 'bg', country: 'Bulgaria' },
  { label: 'Europe/Stockholm', lang: 'sv', country: 'Sweden' },
  { label: 'Europe/Tallinn', lang: 'et', country: 'Estonia' },
  { label: 'Europe/Tirane', lang: 'sq', country: 'Albania' },
  { label: 'Europe/Tiraspol', lang: 'ru', country: 'Moldova' },
  //{ label: 'Europe/Uzhgorod', lang: 'uk', country: '' },
  { label: 'Europe/Vaduz', lang: 'de', country: 'Liechtenstein' },
  { label: 'Europe/Vatican', lang: 'it', country: 'Vatican' },
  { label: 'Europe/Vienna', lang: 'de', country: 'Austria' },
  { label: 'Europe/Vilnius', lang: 'lt', country: 'Lithuania' },
  //{ label: 'Europe/Volgograd', lang: 'ru', country: '' },
  { label: 'Europe/Warsaw', lang: 'pl', country: 'Poland' },
  { label: 'Europe/Zagreb', lang: 'hr', country: 'Croatia' },
  //{ label: 'Europe/Zaporozhye', lang: 'uk', country: '' },
  { label: 'Europe/Zurich', lang: 'de', country: 'Switzerland' },
];

export const allergensFieldIcons = {
  celery: {
    icon: <Celery className="cursor-pointer" />,
    tooltip: 'There is Celery in the dish',
  },
  nocelery: {
    icon: <NoCelery className="cursor-pointer" />,
    tooltip: 'There is no Celery in the dish',
  },
  sulfites: {
    icon: <Sulfites className="cursor-pointer" />,
    tooltip: 'There is Sulfites in the dish',
  },
  nosulfites: {
    icon: <NoSulfites className="cursor-pointer" />,
    tooltip: 'There is no Sulfites in the dish',
  },
  crustaceans: {
    icon: <Crab className="cursor-pointer" />,
    tooltip: 'There are crustaceans in the dish',
  },
  nocrustaceans: {
    icon: <NoCrab className="cursor-pointer" />,
    tooltip: 'There are no crustaceans in the dish',
  },
  eggs: {
    icon: <Eggs className="cursor-pointer" />,
    tooltip: 'There are eggs in the dish',
  },
  noeggs: {
    icon: <NoEggs className="cursor-pointer" />,
    tooltip: 'There are no eggs in the dish',
  },
  fish: {
    icon: <Fish className="cursor-pointer" />,
    tooltip: 'There is fish in the dish',
  },
  nofish: {
    icon: <NoFish className="cursor-pointer" />,
    tooltip: 'There is no fish in the dish',
  },
  gluten: {
    icon: <Gluten className="cursor-pointer" />,
    tooltip: 'There is gluten in the dish',
  },
  nogluten: {
    icon: <NoGluten className="cursor-pointer" />,
    tooltip: 'There is no gluten in the dish',
  },
  milk: {
    icon: <Milk className="cursor-pointer" />,
    tooltip: 'There is milk in the dish',
  },
  nomilk: {
    icon: <NoMilk className="cursor-pointer" />,
    tooltip: 'There is no milk in the dish',
  },
  mustard: {
    icon: <Mustard className="cursor-pointer" />,
    tooltip: 'There is mustard in the dish',
  },
  nomustard: {
    icon: <NoMustard className="cursor-pointer" />,
    tooltip: 'There is no mustard in the dish',
  },
  nuts: {
    icon: <Nuts className="cursor-pointer" />,
    tooltip: 'There are peanuts in the dish',
  },
  nonuts: {
    icon: <NoNuts className="cursor-pointer" />,
    tooltip: 'There are no peanuts in the dish',
  },
  pea: {
    icon: <Pea className="cursor-pointer" />,
    tooltip: 'There is pea in the dish',
  },
  nopea: {
    icon: <NoPea className="cursor-pointer" />,
    tooltip: 'There is no pea in the dish',
  },
  sesame: {
    icon: <Sesame className="cursor-pointer" />,
    tooltip: 'There are sesame seeds in the dish',
  },
  nosesame: {
    icon: <NoSesame className="cursor-pointer" />,
    tooltip: 'There are no sesame seeds in the dish',
  },
  shellfish: {
    icon: <Shellfish className="cursor-pointer" />,
    tooltip: 'There is shellfish in the dish',
  },
  noshellfish: {
    icon: <NoShellfish className="cursor-pointer" />,
    tooltip: 'There is no shellfish in the dish',
  },
  soy: {
    icon: <Soy className="cursor-pointer" />,
    tooltip: 'There is soy in the dish',
  },
  nosoy: {
    icon: <NoSoy className="cursor-pointer" />,
    tooltip: 'There is no soy in the dish',
  },
  seeds: {
    icon: <Seeds className="cursor-pointer" />,
    tooltip: 'There is sunflower seeds in the dish',
  },
  noseeds: {
    icon: <NoSeeds className="cursor-pointer" />,
    tooltip: 'There is no sunflower seeds in the dish',
  },
};

export const allergensFieldIconsMini = {
  celery: {
    icon: <Celery className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is Celery in the dish',
  },
  nocelery: {
    icon: <NoCelery className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no Celery in the dish',
  },
  sulfites: {
    icon: <Sulfites className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is Sulfites in the dish',
  },
  nosulfites: {
    icon: <NoSulfites className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no Sulfites in the dish',
  },
  crustaceans: {
    icon: <Crab className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are crustaceans in the dish',
  },
  nocrustaceans: {
    icon: <NoCrab className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are no crustaceans in the dish',
  },
  eggs: {
    icon: <Eggs className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are eggs in the dish',
  },
  noeggs: {
    icon: <NoEggs className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are no eggs in the dish',
  },
  fish: {
    icon: <Fish className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is fish in the dish',
  },
  nofish: {
    icon: <NoFish className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no fish in the dish',
  },
  gluten: {
    icon: <Gluten className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is gluten in the dish',
  },
  nogluten: {
    icon: <NoGluten className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no gluten in the dish',
  },
  milk: {
    icon: <Milk className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is milk in the dish',
  },
  nomilk: {
    icon: <NoMilk className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no milk in the dish',
  },
  mustard: {
    icon: <Mustard className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is mustard in the dish',
  },
  nomustard: {
    icon: <NoMustard className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no mustard in the dish',
  },
  nuts: {
    icon: <Nuts className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are peanuts in the dish',
  },
  nonuts: {
    icon: <NoNuts className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are no peanuts in the dish',
  },
  pea: {
    icon: <Pea className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is pea in the dish',
  },
  nopea: {
    icon: <NoPea className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no pea in the dish',
  },
  sesame: {
    icon: <Sesame className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are sesame seeds in the dish',
  },
  nosesame: {
    icon: <NoSesame className="cursor-pointer h-6 w-6" />,
    tooltip: 'There are no sesame seeds in the dish',
  },
  shellfish: {
    icon: <Shellfish className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is shellfish in the dish',
  },
  noshellfish: {
    icon: <NoShellfish className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no shellfish in the dish',
  },
  soy: {
    icon: <Soy className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is soy in the dish',
  },
  nosoy: {
    icon: <NoSoy className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no soy in the dish',
  },
  seeds: {
    icon: <Seeds className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is sunflower seeds in the dish',
  },
  noseeds: {
    icon: <NoSeeds className="cursor-pointer h-6 w-6" />,
    tooltip: 'There is no sunflower seeds in the dish',
  },
};

export const dataAllergensIcons: {
  name: string;
  iconNoVis: ReactNode;
  iconVis: ReactNode;
  iconNoAllergen: ReactNode;
}[] = [
  {
    name: 'Crustaceans',
    iconNoVis: <Crab className="cursor-pointer opacity-50" />,
    iconVis: <Crab className="cursor-pointer" />,
    iconNoAllergen: <NoCrab className="cursor-pointer" />,
  },
  {
    name: 'Celery',
    iconNoVis: <Celery className="cursor-pointer opacity-50" />,
    iconVis: <Celery className="cursor-pointer" />,
    iconNoAllergen: <NoCelery className="cursor-pointer" />,
  },
  {
    name: 'Sulfites',
    iconNoVis: <Sulfites className="cursor-pointer opacity-50" />,
    iconVis: <Sulfites className="cursor-pointer" />,
    iconNoAllergen: <NoSulfites className="cursor-pointer" />,
  },
  {
    name: 'Eggs',
    iconNoVis: <Eggs className="cursor-pointer opacity-50" />,
    iconVis: <Eggs className="cursor-pointer" />,
    iconNoAllergen: <NoEggs className="cursor-pointer" />,
  },
  {
    name: 'Fish',
    iconNoVis: <Fish className="cursor-pointer opacity-50" />,
    iconVis: <Fish className="cursor-pointer" />,
    iconNoAllergen: <NoFish className="cursor-pointer" />,
  },
  {
    name: 'Gluten',
    iconNoVis: <Gluten className="cursor-pointer opacity-50" />,
    iconVis: <Gluten className="cursor-pointer" />,
    iconNoAllergen: <NoGluten className="cursor-pointer" />,
  },
  {
    name: 'Milk',
    iconNoVis: <Milk className="cursor-pointer opacity-50" />,
    iconVis: <Milk className="cursor-pointer" />,
    iconNoAllergen: <NoMilk className="cursor-pointer" />,
  },
  {
    name: 'Mustard',
    iconNoVis: <Mustard className="cursor-pointer opacity-50" />,
    iconVis: <Mustard className="cursor-pointer" />,
    iconNoAllergen: <NoMustard className="cursor-pointer" />,
  },
  {
    name: 'Nuts',
    iconNoVis: <Nuts className="cursor-pointer opacity-50" />,
    iconVis: <Nuts className="cursor-pointer" />,
    iconNoAllergen: <NoNuts className="cursor-pointer" />,
  },
  {
    name: 'Pea',
    iconNoVis: <Pea className="cursor-pointer opacity-50" />,
    iconVis: <Pea className="cursor-pointer" />,
    iconNoAllergen: <NoPea className="cursor-pointer" />,
  },
  {
    name: 'Sesame',
    iconNoVis: <Sesame className="cursor-pointer opacity-50" />,
    iconVis: <Sesame className="cursor-pointer" />,
    iconNoAllergen: <NoSesame className="cursor-pointer" />,
  },
  {
    name: 'Shellfish',
    iconNoVis: <Shellfish className="cursor-pointer opacity-50" />,
    iconVis: <Shellfish className="cursor-pointer" />,
    iconNoAllergen: <NoShellfish className="cursor-pointer" />,
  },
  {
    name: 'Soy',
    iconNoVis: <Soy className="cursor-pointer opacity-50" />,
    iconVis: <Soy className="cursor-pointer" />,
    iconNoAllergen: <NoSoy className="cursor-pointer" />,
  },
  {
    name: 'Seeds',
    iconNoVis: <Seeds className="cursor-pointer opacity-50" />,
    iconVis: <Seeds className="cursor-pointer" />,
    iconNoAllergen: <NoSeeds className="cursor-pointer" />,
  },
];

export const weekDays = [
  { name: 'Monday', value: 'monday', id: 1, short: 'mon' },
  { name: 'Tuesday', value: 'tuesday', id: 2, short: 'tue' },
  { name: 'Wednesday', value: 'wednesday', id: 3, short: 'wed' },
  { name: 'Thursday', value: 'thursday', id: 4, short: 'thu' },
  { name: 'Friday', value: 'friday', id: 5, short: 'fri' },
  { name: 'Saturday', value: 'saturday', id: 6, short: 'sat' },
  { name: 'Sunday', value: 'sunday', id: 7, short: 'sun' },
];

export const weekDaysObj = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

export const monthsObj = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const intCurrency = ['HUF'];

export const labelsList: {
  id: number;
  label: Labels;
}[] = [
  {
    id: 1,
    label: 'pork',
  },
  {
    id: 4,
    label: 'beef',
  },
  {
    id: 5,
    label: 'lamb',
  },
  {
    id: 7,
    label: 'chicken',
  },
  {
    id: 2,
    label: 'spicy',
  },
  {
    id: 3,
    label: 'vegan',
  },
  {
    id: 6,
    label: 'vegitarian',
  },
  {
    id: 9,
    label: 'glutenFree',
  },
  {
    id: 15,
    label: 'lactoseFree',
  },
  {
    id: 12,
    label: 'seaFood',
  },
  {
    id: 10,
    label: 'fish',
  },
  {
    id: 8,
    label: 'salmon',
  },
  {
    id: 14,
    label: 'tuna',
  },
  {
    id: 13,
    label: 'shrimp',
  },
  {
    id: 11,
    label: 'mushrooms',
  },
];

export const defaultLabels: Record<Labels, boolean> = {
  pork: false,
  spicy: false,
  vegan: false,
  beef: false,
  lamb: false,
  vegitarian: false,
  chicken: false,
  salmon: false,
  glutenFree: false,
  fish: false,
  mushrooms: false,
  seaFood: false,
  shrimp: false,
  tuna: false,
  lactoseFree: false,
};

export const labelsIcons: Record<Labels, Record<'large' | 'small', ReactNode>> = {
  pork: {
    large: <Pork className="w-8 h-8 mr-3" />,
    small: <Pork className="h-6 w-6 flex items-center" />,
  },
  vegan: {
    large: <Vegan className="w-8 h-8 mr-3" />,
    small: <Vegan className="h-6 w-6" />,
  },
  spicy: {
    large: <Spicy className="w-8 h-8 mr-3" />,
    small: <Spicy className="h-6 w-6 " />,
  },
  beef: {
    large: <Beef className="w-8 h-8 mr-3 " />,
    small: <Beef className="h-6 w-6 " />,
  },
  lamb: {
    large: <Lamb className="w-8 h-8 mr-3 " />,
    small: <Lamb className="h-6 w-6 " />,
  },
  vegitarian: {
    large: <Vegitarian className="w-8 h-8 mr-3 " />,
    small: <Vegitarian className="h-6 w-6 " />,
  },
  chicken: {
    large: <Chicken className="w-8 h-8 mr-3 " />,
    small: <Chicken className="h-6 w-6 " />,
  },
  salmon: {
    large: <Salmon className="w-8 h-8 mr-3" />,
    small: <Salmon className="h-6 w-6 " />,
  },
  glutenFree: {
    large: <GlutenFree className="w-8 h-8 mr-3" />,
    small: <GlutenFree className="h-6 w-6 " />,
  },
  fish: {
    large: <FishLabel className="w-8 h-8 mr-3" />,
    small: <FishLabel className="h-6 w-6 " />,
  },
  mushrooms: {
    large: <Mushrooms className="w-8 h-8 mr-3" />,
    small: <Mushrooms className="h-6 w-6 " />,
  },
  seaFood: {
    large: <Seafood className="w-8 h-8 mr-3" />,
    small: <Seafood className="h-6 w-6 " />,
  },
  shrimp: {
    large: <Shrimp className="w-8 h-8 mr-3" />,
    small: <Shrimp className="h-6 w-6 " />,
  },
  tuna: {
    large: <Tuna className="w-8 h-8 mr-3" />,
    small: <Tuna className="h-6 w-6 " />,
  },
  lactoseFree: {
    large: <LactoseFree className="w-8 h-8 mr-3" />,
    small: <LactoseFree className="h-6 w-6 " />,
  },
};

export const roundedOption: RoundedOption[] = [
  {
    id: 1,
    label: 'standart',
    value: 'standard',
  },
  {
    id: 2,
    label: 'up',
    value: 'up',
  },
  {
    id: 3,
    label: 'down',
    value: 'down',
  },
];
